<template>
  <div
    class="option-bg"
    :class="{
      'hover-option': index === hoverIndex,
      chosen: isChosenOption,
      'option-bg_user': option.avatar
    }"
    :data-index="index"
    @click.stop="clickOption"
  >
    <div class="option-name">
      <div class="option-checkbox" :class="{ 'option-checkbox_chosen': isChosenOption }">
        <i class="icon-check" v-if="checkSelect(option.id)"></i>
      </div>
      <img v-if="option.avatar" :src="returnSrcFile(option.avatar)" alt="avatar" />
      <div
        v-if="option.color"
        class="option-color"
        :style="`background-color: ${option.color}`"
      ></div>
      <span>{{ option[itemName] }}</span>
    </div>
  </div>
</template>

<script>
import { domainStorage } from "@/globalVariables";

export default {
  name: "OptionSelect",
  mixins: [],
  props: {
    option: {
      type: [String, Object, Array, Number]
    },
    value: {
      type: [String, Object, Array, Number]
    },
    itemName: {
      type: [String, Object, Array, Number]
    },
    index: {
      type: [String, Object, Array, Number]
    },
    hoverIndex: {
      type: [String, Object, Array, Number]
    },
    hiddenItems: {
      type: [String, Object, Array, Number],
      default: () => {
        return [];
      }
    }
  },
  components: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {
    returnSrcFile(src) {
      return domainStorage + src;
    },
    clickOption() {
      if (this.value.find((item) => this.option.id === item.id)) {
        this.$emit("deleteChoseOption", this.option);
      } else {
        this.$emit("choseOption", this.option);
      }
    },
    checkSelect(id) {
      return this.value.find((item) => item.id === id);
    }
  },
  computed: {
    isChosenOption() {
      return (
        this.value.findIndex(
          (val) => val.id === this.option.id || val.user_id === this.option.id
        ) !== -1
      );
    }
    // isHiddenOption() {
    //   return (
    //     this.hiddenItems.findIndex(
    //       (val) => val.id === this.option.id || val.user_id === this.option.id
    //     ) !== -1
    //   );
    // }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color";
@import "~@/assets/base/breakpoints/breakpoints";
@import "~@/assets/base/color/colors.scss";
.chosen-option {
  background-color: #f5f5f5;
}
.option-bg {
  display: flex;
  align-items: center;
  width: 100%;
  //height: 60px;
  padding: 10px 8px;

  cursor: pointer;
  user-select: none;
  flex-shrink: 0;

  &_user {
    padding: 10px 8px;

    .option-name {
      font-size: 12px;
    }

    .option-checkbox {
      margin-right: 4px;
    }
  }

  .option-checkbox {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 18px !important;
    height: 18px;
    border: 2px solid #d3d2e0;
    box-sizing: border-box;
    border-radius: 4px;
    margin-right: 8px;

    .icon-check {
      color: #ffffff;
    }

    &_chosen {
      background-color: $action-primary;
      border: 2px solid $action-primary;
    }
  }
  .option-name {
    display: flex;
    align-items: center;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: $text-body-dark;

    span {
      width: 130px;
    }

    .option-color {
      width: 15px;
      height: 15px;
      border-radius: 2px;
      margin-right: 6px;
    }
    img {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      object-fit: cover;
      margin-right: 8px;
    }
  }
}

.hover-option,
.option-bg:hover {
  background: #f1effa;
  border-radius: 4px;
}
</style>
